<template>
  <v-sheet class="pa-5 my-5" :class="!selected_announcement_id && 'custom-border'" rounded="lg">
    <div class="d-flex align-start">
      <v-avatar size="40">
        <v-img :src="item.user.image?item.user.image.url: require('../../../assets/default-photo.png')"
          v-on:error="require('../../../assets/default-photo.png')"/>
      </v-avatar>
      <v-sheet class="ml-3 flex-grow-1">
        <div class="d-flex align-start">
          <div class="">
            <div class="d-inline f15 secondary-2--text fw500 roboto mr-2">{{ user.first_name ? user.first_name : 'Instructor' }} {{ user.last_name ? user.last_name : 'Account' }} {{ user.suffix ? user.suffix : '' }}</div>
            <v-card dense class="pa-2 f12 poppins fw500 text-wrap white--text" width="100%" color="primary" label>{{item.course.title}}</v-card>
          </div>
          <v-spacer></v-spacer>
          <v-sheet :class="$vuetify.breakpoint.smAndDown ? 'hidden-sm-and-down' : 'd-flex align-center'" >
            <div class="d-flex align-center mr-2">
              <v-checkbox dense hide-detail v-model="item.allow_comments"/>
              <p class=" pt-1 roboto f13 fw500">Allow comments</p>  
            </div>
            <v-divider vertical class="mx-2 mt-n2"/>

            <!-- <v-btn class="mt-n2" color="primary" icon x-small @click="edit(item.id)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn> -->
            <v-btn class="mt-n2" color="primary" icon x-small @click="$emit('updateAnnouncement', item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-divider vertical class="mx-1 mt-n2"/>
            <v-btn @click="$emit('remove', item.id)" class="mt-n2" color="danger-1" icon x-small>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-sheet>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="float-right hidden-sm-and-up"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon >
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
              <v-list>
                <v-list-item class="roboto f14" @click="item.allow_comments = !item.allow_comments">
                  Allow comments
                </v-list-item>
                <!-- <v-list-item class="roboto f14" @click="edit(item.id)">
                  Edit Post
                </v-list-item> -->
                <v-list-item class="roboto f14" @click="$emit('updateAnnouncement', item)">
                  Edit Post
                </v-list-item>
                <v-list-item class="roboto f14" @click="$emit('remove', item.id)">
                  Delete Post
                </v-list-item>
              </v-list>
          </v-menu>
        </div>
      </v-sheet>
    </div>
    <div class="my-5">
      <v-sheet
        color="transparent"
        :id="`editable-${item.id}`" 
        contenteditable 
        class="roboto mb-2 f14 pa-3 fw500 secondary-1--text text-justify" 
        v-html="item.content ? $dompurifier(item.content) : ''"
        @focusout="updateContent"
      />
      <div class="d-flex justify-space-between secondary-4--text f12 align-start">
        <span class="px-3 f12 secondary-2--text roboto">{{$dateFormat.calendar(item.created_at)}}</span>
        <!-- <span class="ml-5" v-if="action == 'reply' && item.allow_comments">
          <v-btn v-if="!loading" text color="primary" small class="text-capitalize poppins00">
            <v-icon size="12">mdi-reply-outline</v-icon>
            reply
          </v-btn>
        </span>  -->
      </div>
      
    </div>
    <div class="mt-5">
      <v-form @submit.prevent="submit_comment">
        <v-text-field
          v-if="item.allow_comments"
          filled 
          class="noline my-3 general-custom-field roboto f14 secondary-1--text fw500" 
          dense
          placeholder="Comment"
          hide-details
          :loading="loading"
          v-model="comment"
          append-icon="mdi-send-outline"
          @click:append="submit_comment"
        />
      </v-form>
    </div>
    <v-divider class="my-7"></v-divider>

    <div class="text-center font-italic secondary--text f12" v-if="!item.allow_comments"> Disabled comments</div>
    <v-container fluid class="pa-0 ma-0" >
      <ProfileCard 
        v-if="!selected_announcement_id && item.comments.length > 0" :item="item.comments[0]" action="reply" :level = "1"/>
      <ProfileCard v-else 
        v-for="(comment, i) in item.comments" 
        :key="i" 
        :item="comment" 
        action="reply" 
        class="my-3" 
        :id="`comment-${comment.id}`"
        :allow_comments="item.allow_comments" 
        :selected_announcement_comment_id="selected_announcement_comment_id" 
        :level = "1"/>
    </v-container>
    
    <v-btn v-if="item.comments.length > 1 && !selected_announcement_id" text @click="$emit('setAnnouncement', item.id)" class="mt-3 f12 text-capitalize secondary-2--text">
      Show all comments...
    </v-btn>
    
  </v-sheet>
</template>

<script>
import { mapActions } from 'vuex';
import ProfileCard from './ProfileCard.vue';
export default {
    props: ["item", "user", "selected_announcement_id", "selected_announcement_comment_id", "action", 'level'],
    data: () => ({
      loading: false,
      comment: null,
    }),
    methods: {
      ...mapActions('instructor', ['getInstructorAnnouncementsAction']),
        edit(id) {
            document.getElementById(`editable-${id}`).focus();
        },
        updateContent(event) {
            if (this.item.content == event.target.innerHTML)
                return;
            this.$api.put(`/instructor/announcements/${this.item.id}`, {
                content: event.target.innerHTML,
                allow_comments: this.item.allow_comments
            }).then(res => {
                this.$store.commit("alertMutation", {
                    show: true,
                    text: res.data.message,
                    type: "success"
                });
            });
        },
        async submit_comment(){
          this.loading = true
          await this.$api.post(`/instructor/announcement/comments`, {
            comment: this.comment,
            user_id: this.$store.state.user.id,
            parent_id: null,
            commentable_id: this.item.id,
            announcement_id: this.announcement_id
          }).then(res => {
            this.comment = ''
            this.addReply = false
            this.getInstructorAnnouncementsAction().then(()=>{
              this.loading = false
            })
          })
        },

    },
    watch: {
        "item.allow_comments"(val) {
            this.$api.put(`/instructor/announcements/${this.item.id}`, {
                content: this.item.content,
                allow_comments: val
            }).then(res => {
                this.$store.commit("alertMutation", {
                    show: true,
                    text: res.data.message,
                    type: "success"
                });
            });
        },
        // selected_announcement_id(val){
        //   if(val) {
        //     alert('hello')
        //     setTimeout(() => {
        //       this.$vuetify.goTo(`#comment-${this.selected_announcement_comment_id}`, { offset: 30})
        //     }, 1000)
        //   }
        // }
    },
    components: { ProfileCard }
}
</script>